import React from 'react'
import { Form } from "react-bootstrap"
import Col from "react-bootstrap/Col"


const SelectField = ({name, required, placeholder, values, handlechange, fieldClass, label, grpmd, componentprops, step, type, id}) => (
  <Form.Group as={Col} md={grpmd} className={`${type} ${step}`} controlId={"validation" + name}>
    { label
      ? <Form.Label className="form-label">{label}{required ? '' : ''}</Form.Label>
      : ''

    }
    <Form.Control
    controlId={id}
		className={fieldClass ?fieldClass + ' form-select' :' form-select'}
		required={required}
		name={name}
		onChange={handlechange}
		as="select"
    value={componentprops ? componentprops[name] : ''}
		>
    <option value="" key="">{placeholder}</option>
		<option value="FirstTimeBuyer">First time buyer</option>
		<option value="NotOnTheMarket">Property to sell, not yet on the market</option>
		<option value="PropertyOnTheMarket">Property on the market</option>
		<option value="UnderOffer">Under offer</option>
		<option value="NothingToSell">Nothing to sell</option>
		<option value="Investor">Investor</option>
    </Form.Control>
  </Form.Group>

);

export default SelectField;