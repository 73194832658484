import React,{useState, useEffect} from 'react'
import {
    Button,
    Form,
    Container,
    FormControl,
    Row,
    InputGroup,
    Col
  } from "react-bootstrap";
import { Link } from "gatsby";
import GenerateLink from "../common/site/generate-link"

const FooterMenu=(props)=>{ 
    return (
        <>
                <Col md={12} className="footer-quick-link-list">
                  <div className="footer-menu">
                    <div className={`footer-quick-link-block ${props.isActive ? `open-footer-block` : ``}`}>
                      <div onClick={()=>props.clickHandler(props.i)} className="footer-quick-link-head">
                      <GenerateLink link={props.Link}><span>{props.Label}</span></GenerateLink>
                      
                        <i className="icon-minus-footer"></i>
                        <i className="icon-plus-footer"></i>
                      </div>
                      <div className="footer-all-links">
                        <ul>
                            {
                                props.Menus.map((item,i)=>
                                    <li><GenerateLink link={item.Link}>{item.Label}</GenerateLink></li>
                                )
                            }
                        </ul>
                      </div>
                    </div>
                  </div>
                </Col>
        </>
    )
}
export default FooterMenu