import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import GenerateLink from "../common/site/generate-link"
import $ from 'jquery'
import { navigate, useMatch } from "@reach/router"
import SearchResultInputBox from "../predictive-search/SearchResultBoxHeaderSales"
import SearchResultInputBoxLet from "../predictive-search/SearchResultBoxHeaderLet"
import SearchResultInputBoxNewHome from "../predictive-search/SearchResultBoxHeaderNewHome"
import { removeSpecialChar, hyphenToCaptilize, QueryList } from "../utils/property-search"
const Menudata = (props) => {

  function search(val) {
    let url = "/property/for-sale/";
    var head_val = $("#mobile .head-search-input-box-sales").val()
    var head_val_let = $("#mobile .head-search-input-box-let").val()
    var head_val_new = $("#mobile .head-search-input-box-newhome").val()

    const main_page = typeof window !== 'undefined' ? window.location : ''
    var page = ''
    if (main_page.pathname) {
      page = main_page.pathname.split("/")[1]
    }

    localStorage.setItem('searchValueFullName', val ? val : 'blackwater-newport-isle-of-wight');


    var searcValue = val.split(', ').join('-').replace(/ /g, "-").replace(/'/g, "").toLowerCase();
    if (searcValue == "") {
      localStorage.removeItem('searchValue');
      localStorage.removeItem('searchValueFullName');
    }

    if (props.item?.type == "Residential_Sales") {
      url = "/property/for-sale/"
      if (searcValue !== "") {
        url += "in-" + searcValue + "/"
      } else {
        localStorage.setItem('searchValue', 'blackwater-newport-isle-of-wight');
        url += "in-blackwater-newport-isle-of-wight/radius-8-miles/"
      }
      if ((searcValue == "" && head_val == "") || (searcValue && head_val != "")) {
        navigate(url)
      }
      else {
        $(".select-area-error-head-sales").show().delay(4000).fadeOut();
      }
    }
    else if (props.item?.type == "Residential_Lettings") {
      url = "/property/to-rent/"
      if (searcValue !== "") {
        url += "in-" + searcValue + "/"
      } else {
        localStorage.setItem('searchValue', 'blackwater-newport-isle-of-wight');
        url += "in-blackwater-newport-isle-of-wight/radius-8-miles/"
      }
      if ((searcValue == "" && head_val_let == "") || (searcValue && head_val_let != "")) {
        navigate(url)
      }
      else {
        $(".select-area-error-head-let").show().delay(4000).fadeOut();
      }
    }
    else if (props.item?.type == "New_Homes") {
      url = "/property/new-homes/for-sale/"
      if (searcValue !== "") {
        url += "in-" + searcValue + "/"
      } else {
        url += ""
      }
      if ((searcValue == "" && head_val_new == "") || (searcValue && head_val_new != "")) {
        navigate(url)
      }
      else {
        $(".select-area-error-head-newhome").show().delay(4000).fadeOut();
      }
    }

    localStorage.setItem('navindex', 'home');
    // window.location.href = url;
    if (page == "property") {
      window.location.reload();
    }
  }


  function valuation() {
    navigate(`/sell-your-property/property-valuation`);
  }
  const [areaVal, setAreaVal] = useState('')

  const queryList = QueryList();

  return (
    <li
      className={
        props.item.submenu && props.item.isActive
          ? "active card-header card-header-parent"
          : "card-header card-header-parent "
      }
    >
      <div className="d-flex align-items-center mobile-menu-dropdown-wrapper">
        <GenerateLink link={props.item.link} class={props.item.submenu ? "" : "no-submenu"}>
          {props.item.name}

        </GenerateLink>
        <div onClick={() => props.handlerOpenMenu(props.index)} className="mobile-menu-dropdown">
          {props.item.submenu && props.item.isActive ? (
            <i className="icon-arrow-up icon-arrow-down-menu"></i>
          ) : (
            props.item.submenu && <i className="icon-arrow-down-menu"></i>
          )}
        </div>
      </div>
      {props.item.submenu && props.item.isActive && (
        <div>

          {props.item?.search && props.item.name != "Sell" && props.item.name != "Landlords" &&
            <div className="mobile-menu-reveal-search">
              <div className={`postcode_form search_form_${props.val}`}>
                <h3>{props.item?.text}</h3>
                {props.item?.type ?
                  <form className="header-predictive-search form">
                    {props.item?.type == "Residential_Sales" ?
                      <SearchResultInputBox
                        value={areaVal !== 'blackwater-newport-isle-of-wight' ? hyphenToCaptilize(areaVal) : ''}
                        type={props.item?.type}
                        setValue={(searchValue, isSelected) => {
                          // refine(removeSpecialChar(searchValue)) 
                          if (isSelected) {
                            localStorage.setItem('searchValue', removeSpecialChar(searchValue));
                          } else {
                            localStorage.removeItem('searchValue');
                          }
                          setAreaVal(searchValue)
                        }}
                        placeHolder={`Choose town or area`}
                        areaList={queryList.areaList}
                      /> : props.item?.type == "Residential_Lettings" ?
                        <SearchResultInputBoxLet
                          value={areaVal !== 'blackwater-newport-isle-of-wight' ? hyphenToCaptilize(areaVal) : ''}
                          type={props.item?.type}
                          setValue={(searchValue, isSelected) => {
                            // refine(removeSpecialChar(searchValue)) 
                            if (isSelected) {
                              localStorage.setItem('searchValue', removeSpecialChar(searchValue));
                            } else {
                              localStorage.removeItem('searchValue');
                            }
                            setAreaVal(searchValue)
                          }}
                          placeHolder={`Choose town or area`}
                          areaList={queryList.areaList}
                        /> : props.item?.type == "New_Homes" ?
                          <SearchResultInputBoxNewHome
                            value={areaVal !== 'blackwater-newport-isle-of-wight' ? hyphenToCaptilize(areaVal) : ''}
                            type={props.item?.type}
                            setValue={(searchValue, isSelected) => {
                              // refine(removeSpecialChar(searchValue)) 
                              if (isSelected) {
                                localStorage.setItem('searchValue', removeSpecialChar(searchValue));
                              } else {
                                localStorage.removeItem('searchValue');
                              }
                              setAreaVal(searchValue)
                            }}
                            placeHolder={`Choose town or area`}
                            areaList={queryList.areaList}
                          /> : ''}
                    <Button variant="primary" className="search-btn" onClick={() => { search(areaVal) }}>Start Now<i className="icon-arrow"></i></Button>
                  </form> : ''
                  // <div className="form header-predictive-search">
                  //   <Button variant="secondary" className="search-btn val-cta" onClick={() => { valuation() }}>In-person Valuation<i className="icon-arrow head"></i></Button>
                  //   <Button variant="primary" className="search-btn" onClick={() => { valuation() }}>Instant Valuation<i className="icon-arrow"></i></Button>
                  // </div>
                }
              </div>
            </div>
          }

          <ul className="submenu-list">
            {props.item.submenu.map((item, i) => (
              <li
                className={
                  item.submenu && item.isActive
                    ? "active card-header card-header-child"
                    : "card-header card-header-child"
                }
                onClick={(e) =>
                  props.handlerOpenSubMenu(e, props.index, i, "submenu")
                }
              >
                <GenerateLink link={item.link}>
                  {item.name}

                  {item.submenu && item.isActive ? (
                    <i className="icon-arrow-up"></i>
                  ) : (
                    item.submenu && <i className="icon-arrow-down"></i>
                  )}
                </GenerateLink>
                {item.submenu && item.isActive && (
                  <ul className="submenu-list">
                    {item.submenu.map((item) => (
                      <li
                        onClick={(e) => e.stopPropagation()}
                        className="menu-accordian"
                      >
                        <a href="#">{item}</a>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
    </li>
  );
};

export default Menudata;