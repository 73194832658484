import React from "react";
import { Link } from "gatsby"

import { GetURL } from "./functions";
import { GetAlias } from "./GetAlias";
import BookView from "../../Branches/GlobalBookAViewing"


const GenerateLink = (props, { children }) => {
	let url = ''
	let alias = ''
	if (props.link) {
		url = GetURL(props.link.id)
		alias = GetAlias(props.link.id)
		if (url == "home") {
			url = ''
		}

	}
	return (
		<React.Fragment>
			{url.indexOf("http://") == 0 || url.indexOf("https://") == 0 ?
				<a href={`${url}`} target={(url.indexOf("https://www.arunestates.co.uk/") == 0) ? "" : "_blank"} className={props.class}>{props.children}</a>
				: <React.Fragment>
					{url == "book-a-viewing-form" ?
						<BookView title="Arrange a viewing" branch={'Contact form'} class={props.class}>{props.children}</BookView> : <Link to={`/${url}/`} className={props.class} onClick={() => {
							localStorage.setItem('page-alias', alias);
							localStorage.removeItem('buyer-finder-postcode');
							if (url == "property/for-sale/in-blackwater-newport-isle-of-wight/with-buy-to-let-opportunity/radius-8-miles/" || url == "property/for-sale/in-blackwater-newport-isle-of-wight/radius-8-miles" || url == "property/to-rent/in-blackwater-newport-isle-of-wight/radius-8-miles") {
								localStorage.setItem('searchValue', 'blackwater-newport-isle-of-wight');
							}
							else {
								localStorage.removeItem('searchValue');
							}
						}}>{props.children}</Link>
						// <Link to={`/${url}`} className={props.class}>{props.children}</Link>
					}
				</React.Fragment>
			}
		</React.Fragment>
	)
}

export default GenerateLink;
