import React from 'react'
import { useStaticQuery, graphql } from "gatsby";
import axios from "axios";

export const GetAlias = (id) => {
    const data = useStaticQuery(graphql`
    query {
		allAllMenus {
			edges {
				node {
					URL
					Label
					Template
					Alias
					_id
				}
			}
		}
    }
  `);

    let PageURL = data.allAllMenus.edges.filter(item => item.node._id === id).pop().node;


    let URL = PageURL?.Alias

    return URL;

}